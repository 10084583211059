
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { firebaseErrors } from "../methods/firebaseErrors";
import googleSignIn from "../methods/googleSignIn";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { getLoggedInUserWithRelations } from "@/api/user.api";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    // Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(6).required().label("Password"),
    });

    // Form submit function
    const onSubmitLogin = (values) => {
      loading.value = true;
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      const auth = getAuth();
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then(async () => {
          try {
            const {
              data: { data },
            } = await getLoggedInUserWithRelations();
            localStorage.setItem("login-event", "login" + Math.random());
            if (data.role === 1) {
              await router.push("/pitch");
            } else if (data.role === 2) {
              await router.push("/profile/overview");
            } else {
              await router.push("/dashboard");
            }
          } catch ({
            response: {
              data: { statusCode, message },
            },
          }) {
            if (statusCode === 404) {
              await router.push("/account-selection");
            }
            console.error(statusCode, message);
          }
        })
        .catch((error) => {
          console.error(error);
          firebaseErrors(error.code);
        })
        .finally(() => {
          loading.value = false;
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (submitButton.value) {
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          }
        });
    };

    // Google sign in
    const signInWithGoogle = () => {
      googleSignIn();
    };

    return {
      onSubmitLogin,
      login,
      signInWithGoogle,
      submitButton,
    };
  },
});
